import React from 'react';
import './form-builder.form.interface.video.sass';

const FormBuilderFormInterfaceVideo = ({ item, isPreview, language }) => {
  if (!item) return null;
  let url = item.src;

  // on form preview we do not want video to play in the background
  if (isPreview) {
    const defaultAutoplayValue = 0;
    const parsedUrl = new URL(item.src);
    const urlParams = new URLSearchParams(parsedUrl.search);
    urlParams.set('autoplay', defaultAutoplayValue);
    parsedUrl.search = urlParams.toString();
    url = parsedUrl.href;
  }

  return (
    <iframe
      className={item.class}
      src={`${url}${language ? `&cc_lang_pref=${language}` : ''}`}
      id={item.id}
      allow={item.allow}
      allowFullScreen
    />
  );
};

FormBuilderFormInterfaceVideo.displayName = 'FormBuilderFormInterfaceVideo';
export default React.memo(FormBuilderFormInterfaceVideo);
