import { FORM_BUILDER_INPUT_CLASSES } from './form-builder.types';
import {
  DATE,
  EMAIL,
  FILE_UPLOAD,
  MULTI_LINE_TEXT,
  PHONE,
  PRODUCT_ASSOCIATION,
  SINGLE_LINE_TEXT,
} from './form-builder.form-elements.config';
import { formBuilderCustomTranslations } from '../services/translator/form-builder.translations';
import { _generateTranslation } from '../services/translator/form-builder.translator.service';
import { FormBuilderSlugService } from '../services/builder/form-builder.slug.service';

export const createInputLabelGroup = ({
  slug,
  index,
  inputValue,
  labelValue,
  inputName,
  inputType,
  combineSlugAndIndex = false,
  placeholder,
  ariaDescribedBy,
  required = false,
}) => [
  {
    for: combineSlugAndIndex ? `${slug}_${index}` : slug,
    nodeName: 'label',
    nodeValue: labelValue,
  },
  {
    id: combineSlugAndIndex ? `${slug}_${index}` : slug,
    name: inputName || slug,
    value: inputValue,
    type: inputType,
    nodeName: 'input',
    'aria-describedby': ariaDescribedBy,
    required,
    ...(placeholder ? { placeholder: placeholder } : {}),
    ...(ariaDescribedBy ? { 'aria-describedby': ariaDescribedBy } : {}),
  },
];

export const SCALE_ELEMENT = ({
  slug,
  scaleLength,
  customValues = {},
  indexAsValue,
  ariaDescribedBy,
  indexStart,
  inputName,
  required,
}) => {
  return Array.from({ length: scaleLength }, (_, i) => {
    const index = indexStart ? indexStart + i : i;
    const ratingNumber = indexAsValue ? index : index + 1;

    return createInputLabelGroup({
      slug,
      inputName,
      required,
      index: ratingNumber,
      inputValue: ratingNumber,
      labelValue: ratingNumber,
      inputType: 'radio',
      combineSlugAndIndex: true,
      ariaDescribedBy: ariaDescribedBy,
      ...customValues[i],
    });
  }).flat();
};

export const CHOICE_ELEMENT = ({ slug, options, inputType, ariaDescribedBy, inputName }) => {
  return Array.from({ length: options.length }, (_, index) => {
    const inputNumber = index + 1;
    return createInputLabelGroup({
      slug,
      index: inputNumber,
      inputName,
      inputValue: options[index].value.replace(' ', '_').toLowerCase(),
      labelValue: options[index].value,
      inputType: inputType,
      combineSlugAndIndex: true,
      ariaDescribedBy,
    });
  }).flat();
};

export const MATRIX_TR = ({ slug, questions, answers, inputType = 'radio' }) => {
  return questions.map((question, questionIndex) => {
    return {
      nodeName: 'tr',
      elements: [
        { nodeName: 'th', nodeValue: question.label, id: question.id },
        ...answers.flatMap((answer, answerIndex) => {
          const inputIndex = questionIndex * questions.length + answerIndex;
          return {
            ...MATRIX_TD({
              inputType,
              slug,
              inputIndex,
              questionValue: question.value,
              answerValue: answer.value,
              questionId: question.id,
              answerId: answer.id,
            }),
          };
        }),
      ],
    };
  });
};

export const MATRIX_TD = ({
  slug,
  questionValue,
  questionId,
  answerValue,
  answerId,
  inputType = 'radio',
  inputIndex,
  name,
  required = false,
}) => {
  const inputName = `${name || slug}[${questionValue}]`;

  return {
    nodeName: 'td',
    elements: [
      {
        nodeName: 'input',
        type: inputType,
        id: `${slug}_${inputIndex}`,
        name: inputType === 'checkbox' ? `${inputName}[]` : inputName,
        value: answerValue,
        'aria-labelledby': `${questionId} ${answerId}`,
        required,
      },
    ],
  };
};

const createElement = (nodeName, nodeValue) => ({
  nodeName,
  nodeValue,
});

const createLabel = (forString, nodeValue) => ({
  nodeName: 'label',
  for: forString,
  nodeValue,
});

export const createHint = (id, nodeValue) => ({
  nodeName: 'p',
  id,
  nodeValue: nodeValue || '',
});

export const createFormBuilderElement = (
  type,
  i,
  {
    paragraphT,
    emailLabelT,
    fullNameFirstNameLabelT,
    fullNameLastNameLabelT,
    uploadLabelT,
    starRatingLabelT,
    multiLineLabelT,
    multiLinePlaceholderT,
    singleLineLabelT,
    singleChoiceLabelT,
    multipleChoiceLabelT,
    thumbScaleLabelT,
    websiteT,
    phoneLabelT,
    smileyScaleLabelT,
    communityConsentLabelT,
    acceptanceLabelT,
    communityMinimumAge,
    dateLabelT,
    birthdayLabelT,
    addDefaultTranslation,
    numberScaleLabelT,
    numberScaleLabelMinT,
    numberScaleLabelMaxT,
    props = {},
    allElements = [],
    customNames = {},
  } = {}
) => {
  switch (type) {
    case FORM_BUILDER_INPUT_CLASSES.PARAGRAPH: {
      return {
        nodeName: 'p',
        class: FORM_BUILDER_INPUT_CLASSES.PARAGRAPH,
        nodeValue:
          paragraphT ||
          "We're constantly working to improve your experience. Sharing your feedback helps us understand what you like and what we can do better. Whether it's a glowing review or constructive criticism, your feedback will be kept confidential and will be used to make our offering the best it can be. Thanks for taking the time to help us improve!",
      };
    }
    case FORM_BUILDER_INPUT_CLASSES.LINE:
      return {
        nodeName: 'hr',
        class: FORM_BUILDER_INPUT_CLASSES.LINE,
      };
    case FORM_BUILDER_INPUT_CLASSES.FULL_NAME: {
      const firstNameSlug = `first_name_${i}`;
      const lastNameSlug = `last_name_${i}`;

      return {
        nodeName: 'fieldset',
        class: FORM_BUILDER_INPUT_CLASSES.FULL_NAME,
        elements: [
          { nodeName: 'legend', content: 'Name' },
          {
            nodeName: 'div',
            class: 'input',
            elements: [
              createLabel(customNames.firstNameSlug || firstNameSlug, fullNameFirstNameLabelT || 'First Name'),
              {
                id: firstNameSlug,
                name: customNames.firstNameSlug || firstNameSlug,
                ...SINGLE_LINE_TEXT,
                required: true,
                autocomplete: 'given-name',
                ...props,
              },
            ],
          },
          {
            nodeName: 'div',
            class: 'input',
            elements: [
              createLabel(customNames.lastNameSlug || lastNameSlug, fullNameLastNameLabelT || 'Last Name'),
              {
                id: lastNameSlug,
                name: customNames.lastNameSlug || lastNameSlug,
                ...SINGLE_LINE_TEXT,
                required: true,
                autocomplete: 'family-name',
                ...props,
              },
            ],
          },
        ],
      };
    }
    case FORM_BUILDER_INPUT_CLASSES.EMAIL: {
      const emailSlug = `email_${i}`;
      const emailHintSlug = `email_hint_${i}`;

      return {
        nodeName: 'div',
        class: FORM_BUILDER_INPUT_CLASSES.EMAIL,
        elements: [
          createLabel(customNames.emailSlug || emailSlug, emailLabelT || 'Email address'),
          {
            id: emailSlug,
            name: customNames.emailSlug || emailSlug,
            'aria-describedby': emailHintSlug,
            ...EMAIL,
            ...props,
          },
          createHint(emailHintSlug),
        ],
      };
    }
    case FORM_BUILDER_INPUT_CLASSES.DATE: {
      const dateSlug = `date_${i}`;

      return {
        nodeName: 'div',
        class: FORM_BUILDER_INPUT_CLASSES.DATE,
        elements: [createLabel(dateSlug, dateLabelT || 'Date'), { id: dateSlug, name: dateSlug, ...DATE, ...props }],
      };
    }
    case FORM_BUILDER_INPUT_CLASSES.BIRTHDAY: {
      const birthdaySlug = `birthday_${i}`;

      return {
        nodeName: 'div',
        class: FORM_BUILDER_INPUT_CLASSES.BIRTHDAY,
        elements: [
          createLabel(birthdaySlug, birthdayLabelT || 'Birthday'),
          {
            id: birthdaySlug,
            name: birthdaySlug,
            ...DATE,
            autocomplete: 'bday',
            ...props,
          },
        ],
      };
    }
    case FORM_BUILDER_INPUT_CLASSES.PHONE: {
      const phoneSlug = `phone_${i}`;
      const phoneHintSlug = `phone_hint_${i}`;

      return {
        nodeName: 'div',
        class: FORM_BUILDER_INPUT_CLASSES.PHONE,
        elements: [
          createLabel(phoneSlug, phoneLabelT || 'Phone Number'),
          { id: phoneSlug, name: phoneSlug, 'aria-describedby': phoneHintSlug, ...PHONE },
          createHint(phoneHintSlug),
        ],
      };
    }
    case FORM_BUILDER_INPUT_CLASSES.HEADING:
      return {
        nodeName: 'hgroup',
        class: FORM_BUILDER_INPUT_CLASSES.HEADING,
        elements: [createElement('h2', 'Heading'), createElement('p', 'Heading paragraph')],
      };
    case FORM_BUILDER_INPUT_CLASSES.SPACER: {
      const spacerSlug = `spacer_${i}`;

      return {
        id: spacerSlug,
        nodeName: 'div',
        class: FORM_BUILDER_INPUT_CLASSES.SPACER,
      };
    }
    case FORM_BUILDER_INPUT_CLASSES.HEADER:
      return {
        nodeName: 'hgroup',
        class: FORM_BUILDER_INPUT_CLASSES.HEADER,
        elements: [createElement('h1', 'Feedback Form'), createElement('p', 'Submit your feedback to us')],
      };
    case FORM_BUILDER_INPUT_CLASSES.WEBSITE: {
      const websiteSlug = `website_${i}`;
      const websiteHintSlug = `website_hint_${i}`;

      return {
        nodeName: 'div',
        class: FORM_BUILDER_INPUT_CLASSES.WEBSITE,
        elements: [
          createLabel(websiteSlug, websiteT || 'Website'),
          {
            ...SINGLE_LINE_TEXT,
            id: websiteSlug,
            name: websiteSlug,
            type: 'url',
            'aria-describedby': websiteHintSlug,
          },
          createHint(websiteHintSlug),
        ],
      };
    }
    case FORM_BUILDER_INPUT_CLASSES.SINGLE_LINE_TEXT: {
      const singleLineSlug = `single_line_${i}`;
      const singleLineHintSlug = `single_line_hint_${i}`;

      return {
        nodeName: 'div',
        class: FORM_BUILDER_INPUT_CLASSES.SINGLE_LINE_TEXT,
        elements: [
          createLabel(customNames.singleLineSlug || singleLineSlug, singleLineLabelT || 'Single Line'),
          {
            id: singleLineSlug,
            name: customNames.singleLineSlug || singleLineSlug,
            'aria-describedby': singleLineHintSlug,
            ...SINGLE_LINE_TEXT,
          },
          createHint(singleLineHintSlug),
        ],
      };
    }
    case FORM_BUILDER_INPUT_CLASSES.MULTI_LINE_TEXT: {
      const multiLineSlug = `multi_line_${i}`;
      const multiLineHintSlug = `multi_line_hint_${i}`;

      return {
        nodeName: 'div',
        class: FORM_BUILDER_INPUT_CLASSES.MULTI_LINE_TEXT,
        elements: [
          createLabel(customNames.multiLineSlug || multiLineSlug, multiLineLabelT || 'Multi Line'),
          {
            id: multiLineSlug,
            name: customNames.multiLineSlug || multiLineSlug,
            'aria-describedby': multiLineHintSlug,
            ...MULTI_LINE_TEXT,
            placeholder: multiLinePlaceholderT || '',
          },
          createHint(multiLineHintSlug),
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.IMAGE: {
      const imageSlug = `image_${i}`;

      return {
        nodeValue: 'figure',
        class: FORM_BUILDER_INPUT_CLASSES.IMAGE,
        elements: [{ id: imageSlug, nodeName: 'img', src: '', alt: '' }],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.FILE_UPLOAD: {
      const uploadSlug = `media_${i}`;
      const uploadHintSlug = `media_hint_${i}`;
      const parentElement = { class: FORM_BUILDER_INPUT_CLASSES.FILE_UPLOAD };

      const dataTranslations = Object.keys(FILE_UPLOAD['data-locale']).map(key => {
        return {
          [key]: _generateTranslation(parentElement, FILE_UPLOAD),
        };
      });

      dataTranslations.forEach(tr => {
        addDefaultTranslation({
          [Object.values(tr)[0]]: formBuilderCustomTranslations['data-locale'][Object.keys(tr)[0]],
        });
      });

      return {
        nodeName: 'div',
        class: FORM_BUILDER_INPUT_CLASSES.FILE_UPLOAD,
        elements: [
          createLabel(customNames.uploadSlug || uploadSlug, uploadLabelT || 'Media'),
          {
            id: uploadSlug,
            name: customNames.uploadSlug || uploadSlug,
            nodeValue: 'Choose file or drop here',
            'aria-describedby': uploadHintSlug,
            ...FILE_UPLOAD,
            'data-locale': JSON.stringify(
              dataTranslations.reduce((acc, obj) => {
                return { ...acc, ...obj };
              }, {})
            ),
            ...props,
          },
          createHint(uploadHintSlug),
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.STAR_RATING: {
      const ratingSlug = `rating_${i}`;
      const starsCount = 5;

      return {
        nodeName: 'fieldset',
        class: FORM_BUILDER_INPUT_CLASSES.STAR_RATING,
        elements: [
          { nodeName: 'legend', nodeValue: starRatingLabelT || 'Star Rating' },
          ...SCALE_ELEMENT({
            slug: ratingSlug,
            scaleLength: starsCount,
            inputName: customNames.ratingSlug || ratingSlug,
          }),
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.SMILEY_SCALE: {
      const smileyScaleSlug = `smiley_scale_${i}`;
      const scaleCount = 5;

      return {
        nodeName: 'fieldset',
        class: FORM_BUILDER_INPUT_CLASSES.SMILEY_SCALE,
        elements: [
          { nodeName: 'legend', nodeValue: smileyScaleLabelT || 'Smiley Scale' },
          ...SCALE_ELEMENT({ slug: smileyScaleSlug, scaleLength: scaleCount }),
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.NUMBER_SCALE: {
      const numberScaleSlug = customNames.numberScaleSlug || `number_scale_${i}`;
      const numberScaleLabelMinSlug = `number_scale_${i}_min`;
      const numberScaleLabelMaxSlug = `number_scale_${i}_max`;

      const restOfElementsCount = 10;
      const restOfElementsStartIndex = 1;
      const firstInputIndex = 0;

      const defaultAriaDescribedByValues = Array.from({ length: restOfElementsCount }, (_, index) => {
        return index === restOfElementsCount - 1 ? { ariaDescribedBy: numberScaleLabelMaxSlug } : {};
      });

      return {
        nodeName: 'fieldset',
        class: FORM_BUILDER_INPUT_CLASSES.NUMBER_SCALE,
        elements: [
          { nodeName: 'legend', nodeValue: numberScaleLabelT || 'Number Scale' },
          ...createInputLabelGroup({
            slug: numberScaleSlug,
            index: firstInputIndex,
            inputValue: firstInputIndex,
            labelValue: firstInputIndex,
            inputType: 'radio',
            combineSlugAndIndex: true,
            required: props.required,
            ariaDescribedBy: numberScaleLabelMinSlug,
          }),
          {
            nodeName: 'div',
            nodeValue: numberScaleLabelMinT || 'Least Likely',
            'aria-hidden': true,
            id: numberScaleLabelMinSlug,
          },
          ...SCALE_ELEMENT({
            slug: numberScaleSlug,
            scaleLength: restOfElementsCount,
            customValues: defaultAriaDescribedByValues,
            indexAsValue: true,
            indexStart: restOfElementsStartIndex,
            required: props.required
          }),
          {
            nodeName: 'div',
            nodeValue: numberScaleLabelMaxT || 'Most Likely',
            'aria-hidden': true,
            id: numberScaleLabelMaxSlug,
          },
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.DROPDOWN: {
      const dropdownSlug = `dropdown_${i}`;
      const dropdownHintSlug = `dropdown_hint_${i}`;
      const defaultDropdownOptions = [{ value: 'Option 1' }, { value: 'Option 2' }, { value: 'Option 3' }];

      return {
        nodeName: 'div',
        class: FORM_BUILDER_INPUT_CLASSES.DROPDOWN,
        elements: [
          createLabel(dropdownSlug, 'Dropdown'),
          {
            id: dropdownSlug,
            name: dropdownSlug,
            nodeName: 'select',
            required: false,
            'aria-describedby': dropdownHintSlug,
            elements: [
              ...defaultDropdownOptions.map(option => {
                return {
                  nodeName: 'option',
                  value: option.value,
                  nodeValue: option.value,
                };
              }),
            ],
          },
          createHint(dropdownHintSlug),
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE: {
      const singleChoiceSlug = `single_choice_${i}`;
      const defaultSingleChoiceOptions = [{ value: 'Option 1' }, { value: 'Option 2' }, { value: 'Option 3' }];

      return {
        nodeName: 'fieldset',
        class: FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE,
        elements: [
          { nodeName: 'legend', nodeValue: singleChoiceLabelT || 'Single Choice' },
          ...CHOICE_ELEMENT({
            slug: singleChoiceSlug,
            options: defaultSingleChoiceOptions,
            inputType: 'radio',
          }),
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.MULTIPLE_CHOICE: {
      const multipleChoiceSlug = `multiple_choice_${i}`;
      const defaultMultipleChoiceOptions = [{ value: 'Option 1' }, { value: 'Option 2' }, { value: 'Option 3' }];

      return {
        nodeName: 'fieldset',
        class: FORM_BUILDER_INPUT_CLASSES.MULTIPLE_CHOICE,
        elements: [
          { nodeName: 'legend', nodeValue: multipleChoiceLabelT || 'Multiple Choice' },
          ...CHOICE_ELEMENT({
            slug: multipleChoiceSlug,
            inputName: `${multipleChoiceSlug}[]`,
            options: defaultMultipleChoiceOptions,
            inputType: 'checkbox',
          }),
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.THUMB_SCALE: {
      const thumbScaleSlug = `thumb_scale_${i}`;

      const defaultThumbScaleValues = [
        { inputValue: 'true', labelValue: 'Approve' },
        { inputValue: 'false', labelValue: 'Disapprove' },
      ];

      return {
        nodeName: 'fieldset',
        class: FORM_BUILDER_INPUT_CLASSES.THUMB_SCALE,
        elements: [
          { nodeName: 'legend', nodeValue: thumbScaleLabelT || 'Thumb Scale' },
          ...SCALE_ELEMENT({
            slug: thumbScaleSlug,
            scaleLength: defaultThumbScaleValues.length,
            customValues: defaultThumbScaleValues,
          }),
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.CONSENT: {
      const consentSlug = `consent_${i}`;
      return {
        nodeName: 'div',
        class: FORM_BUILDER_INPUT_CLASSES.CONSENT,
        elements: [
          {
            nodeName: 'input',
            type: 'checkbox',
            value: null,
            name: consentSlug,
            id: consentSlug,
            required: false,
          },
          {
            nodeName: 'label',
            for: consentSlug,
            elements: [
              { nodeName: '#text', nodeValue: 'By submitting my photo and additional information I agree to these ' },
              { nodeName: 'a', href: null, nodeValue: 'terms and conditions', target: '_blank' },
            ],
          },
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.COMMUNITY_CONSENT: {
      const communityConsent = `community_consent_${i}`;
      return {
        nodeName: 'div',
        class: FORM_BUILDER_INPUT_CLASSES.COMMUNITY_CONSENT,
        elements: [
          {
            nodeName: 'input',
            type: 'checkbox',
            value: null,
            name: communityConsent,
            id: communityConsent,
            required: false,
            'data-mapping': '',
            ...props,
          },
          {
            nodeName: 'label',
            for: communityConsent,
            nodeValue:
              communityConsentLabelT ||
              (communityMinimumAge === ''
                ? 'Sign me up for the exclusive {{name}} community where I can score exclusive offers, try new products and participate in discussions. I agree with both its {{terms_link}} and {{privacy_policy_link}}.'
                : "Sign me up for the exclusive {{name}} community where I can score exclusive offers, try new products and participate in discussions. I attest I'm {{minimum_age}} or older and that I agree with both its {{terms_link}} and {{privacy_policy_link}}."),
          },
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.ACCEPTANCE: {
      const acceptanceSlug = `acceptance_${i}`;
      return {
        nodeName: 'div',
        class: FORM_BUILDER_INPUT_CLASSES.ACCEPTANCE,
        elements: [
          {
            nodeName: 'input',
            type: 'checkbox',
            value: 'true',
            name: acceptanceSlug,
            id: acceptanceSlug,
            required: false,
          },
          {
            nodeName: 'label',
            for: acceptanceSlug,
            elements: [
              { nodeName: '#text', nodeValue: acceptanceLabelT || 'I would like to sign up for the newsletter' },
              { nodeName: 'a', href: '', nodeValue: '' },
            ],
          },
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.PRODUCT_ASSOCIATION: {
      const productAssociationSlug = `product_association_${i}`;
      return {
        class: FORM_BUILDER_INPUT_CLASSES.PRODUCT_ASSOCIATION,
        name: productAssociationSlug,
        id: productAssociationSlug,
        ...PRODUCT_ASSOCIATION,
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.HIDDEN: {
      const hiddenSlug = `hidden_${i}`;
      return {
        class: FORM_BUILDER_INPUT_CLASSES.HIDDEN,
        name: hiddenSlug,
        id: hiddenSlug,
        type: 'hidden',
        nodeName: 'input',
        value: '',
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.SINGLE_MATRIX: {
      const singleMatrixSlug = `single_matrix_${i}`;
      const createOptions = (label, value) => {
        const slug = `${singleMatrixSlug}_${value}`;
        const oldElements = [...allElements];

        return Array.from({ length: 3 }, () => {
          const elementIndex = FormBuilderSlugService.getSlugIndex({
            customSlug: slug,
            allElements: oldElements,
            index: 1,
          });

          const newOption = {
            label: `${label} ${elementIndex}`,
            value: `${value}_${elementIndex}`,
            id: `${slug}_${elementIndex}`,
          };

          oldElements.push(newOption);

          return newOption;
        });
      };

      const questions = createOptions('Question', 'question');
      const answers = createOptions('Answer', 'answer');

      return {
        class: FORM_BUILDER_INPUT_CLASSES.SINGLE_MATRIX,
        nodeName: 'fieldset',
        elements: [
          { nodeName: 'legend', nodeValue: 'Single Matrix', id: `${singleMatrixSlug}_legend` },
          {
            nodeName: 'table',
            'aria-describedby': `${singleMatrixSlug}_legend`,
            elements: [
              {
                nodeName: 'thead',
                elements: [
                  {
                    nodeName: 'tr',
                    elements: [
                      { nodeName: 'th', nodeValue: '' },
                      ...answers.flatMap(answer => {
                        return { nodeName: 'th', nodeValue: answer.label, id: answer.id };
                      }),
                    ],
                  },
                ],
              },
              {
                nodeName: 'tbody',
                elements: [...MATRIX_TR({ answers, questions, slug: singleMatrixSlug })],
              },
            ],
          },
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.MULTIPLE_MATRIX: {
      const multipleMatrixSlug = `multiple_matrix_${i}`;
      const createOptions = (label, value) => {
        const slug = `${multipleMatrixSlug}_${value}`;
        const oldElements = [...allElements];

        return Array.from({ length: 3 }, () => {
          const elementIndex = FormBuilderSlugService.getSlugIndex({
            customSlug: slug,
            allElements: oldElements,
            index: 1,
          });

          const newOption = {
            label: `${label} ${elementIndex}`,
            value: `${value}_${elementIndex}`,
            id: `${slug}_${elementIndex}`,
          };

          oldElements.push(newOption);

          return newOption;
        });
      };

      const questions = createOptions('Question', 'question');
      const answers = createOptions('Answer', 'answer');

      return {
        class: FORM_BUILDER_INPUT_CLASSES.MULTIPLE_MATRIX,
        nodeName: 'fieldset',
        elements: [
          { nodeName: 'legend', nodeValue: 'Multiple Matrix', id: `${multipleMatrixSlug}_legend` },
          {
            nodeName: 'table',
            'aria-describedby': `${multipleMatrixSlug}_legend`,
            elements: [
              {
                nodeName: 'thead',
                elements: [
                  {
                    nodeName: 'tr',
                    elements: [
                      { nodeName: 'th', nodeValue: '' },
                      ...answers.flatMap(answer => {
                        return { nodeName: 'th', nodeValue: answer.label, id: answer.id };
                      }),
                    ],
                  },
                ],
              },
              {
                nodeName: 'tbody',
                elements: [...MATRIX_TR({ answers, questions, slug: multipleMatrixSlug, inputType: 'checkbox' })],
              },
            ],
          },
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.ADDRESS: {
      const addressSlug = `address_${i}`;
      const defaultCountry = { value: 'US' };

      const addressElements = [
        {
          autoComplete: 'address-line1',
          id: `${addressSlug}_address_line_1_${i}`,
          name: `address_line_1_${i}`,
          type: 'text',
          nodeName: 'input',
          nodeValue: 'Address Line 1',
          required: false,
        },
        {
          autoComplete: 'address-line2',
          id: `${addressSlug}_address_line_2_${i}`,
          name: `address_line_2_${i}`,
          type: 'text',
          nodeName: 'input',
          nodeValue: 'Address Line 2',
          required: false,
        },
        {
          autoComplete: 'address-line3',
          id: `${addressSlug}_address_line_3_${i}`,
          name: `address_line_3_${i}`,
          type: 'text',
          nodeName: 'input',
          nodeValue: 'Address Line 3',
          required: false,
        },
        {
          autoComplete: 'address-level2',
          id: `${addressSlug}_address_level_2_${i}`,
          name: `address_level_2_${i}`,
          type: 'text',
          nodeName: 'input',
          nodeValue: 'City',
          required: false,
        },
        {
          autoComplete: 'country',
          id: `${addressSlug}_country_${i}`,
          name: `country_${i}`,
          nodeName: 'select',
          nodeValue: 'Country',
          'aria-describedby': 'country_0',
          required: false,
          elements: [{ nodeName: 'option', ...defaultCountry }],
        },
        {
          autoComplete: 'address-level1',
          id: `${addressSlug}_address_level_1_${i}`,
          name: `address_level_1_${i}`,
          nodeName: 'select',
          nodeValue: 'State',
          elements: [],
          required: false,
        },
        {
          autoComplete: 'postal-code',
          id: `${addressSlug}_postal_code_${i}`,
          name: `postal_code_${i}`,
          type: 'text',
          nodeName: 'input',
          nodeValue: 'Zip Code',
          required: false,
        },
      ];

      return {
        class: FORM_BUILDER_INPUT_CLASSES.ADDRESS,
        nodeName: 'fieldset',
        elements: [
          { nodeName: 'legend', nodeValue: 'Address' },
          ...addressElements.map(el => {
            return {
              nodeName: 'div',
              elements: [
                createLabel(el.id, el.nodeValue),
                {
                  nodeName: el.nodeName,
                  autocomplete: el.autoComplete,
                  id: el.id,
                  name: el.name,
                  placeholder: '',
                  required: el.required,
                  type: el.type,
                  elements: el.elements,
                },
              ],
            };
          }),
        ],
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.YOUTUBE: {
      const youtubeSlug = `youtube_${i}`;
      return {
        class: FORM_BUILDER_INPUT_CLASSES.YOUTUBE,
        id: youtubeSlug,
        nodeName: 'iframe',
        allow: 'autoplay; encrypted-media',
        src: 'https://www.youtube-nocookie.com/embed/?autoplay=0&controls=0',
      };
    }

    case FORM_BUILDER_INPUT_CLASSES.VIMEO: {
      const vimeoSlug = `vimeo_${i}`;
      return {
        class: FORM_BUILDER_INPUT_CLASSES.VIMEO,
        id: vimeoSlug,
        nodeName: 'iframe',
        src: 'https://player.vimeo.com/video/?dnt=1&autoplay=0&controls=1',
      };
    }

    default:
      return;
  }
};
