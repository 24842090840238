import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';
import { useFormBuilderCountries } from '../../../../hooks/use-form-builder-countries';

const FormBuilderFormInterfaceAddress = ({ styles, item, values, formId, locale, onPending, isPreview }) => {
  if (!item) return null;

  const { t } = useTranslation();
  const { localizedCountriesList, onCountryChange, updateCountryDetails, countryDetails } = useFormBuilderCountries({
    item,
    formId,
    locale,
    onPending,
  });

  const elements = item.elements;
  const legend = elements.find(el => el.nodeName === 'legend');
  const divs = elements.filter(el => el.nodeName === 'div');
  const defaultSubdivision = countryDetails?.included?.find(el => el?.type === 'subdivision');
  const codePattern = countryDetails?.data?.attributes?.postal_code_pattern;

  useEffect(() => {
    onCountryChange({ localizeCountryNames: true });
    if (isPreview && localizedCountriesList.length) {
      const defaultCountry = localizedCountriesList?.[0]?.value;
      updateCountryDetails(defaultCountry);
    }
  }, []);

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
  };

  const renderSelectElement = (element, label, isStateInput, previewList) => {
    const list = isStateInput ? countryDetails?.subdivisionsList : localizedCountriesList;

    return (
      <>
        <label style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS_LABEL] }} htmlFor={label.for}>
          {t(label.nodeValue)}
        </label>
        <select
          style={{
            ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SELECT],
            ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT],
            ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS],
            ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS_SELECT],
          }}
          onChange={isStateInput ? null : e => onCountryChange({ e })}
          onFocus={onFocus}
          name={element.name}
          id={element.id}
          autoComplete={element.autocomplete}
          defaultValue={values?.getAll(element.name)?.length ? values.getAll(element.name)[0] : ''}
          required={element.required}>
          {(isPreview ? previewList : list).map((option, index) => {
            return (
              <option key={`${option.value}-${index}`} value={option.value} id={option.value}>
                {option.name}
              </option>
            );
          })}
        </select>
      </>
    );
  };

  return (
    <fieldset
      className={item.class}
      style={{
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS],
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS],
      }}>
      <legend
        style={{
          ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.LABEL],
          ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS_LEGEND],
        }}>
        {t(legend.nodeValue)}
      </legend>
      {divs.map((div, index) => {
        const label = div.elements.find(el => el?.nodeName === 'label');
        const element = div.elements.find(el => el?.nodeName === 'input' || el?.nodeName === 'select');
        const isPostalCodeInput = element.autocomplete.includes('postal-code');
        const isStateInput = element.autocomplete.includes('address-level1');

        const previewList = isStateInput
          ? [{ value: defaultSubdivision?.id, name: defaultSubdivision?.attributes?.name }]
          : [{ value: countryDetails?.data?.id, name: countryDetails?.data?.attributes?.name }];

        return (
          <div key={`${element.id}-${index}`} style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS_DIV] }}>
            {element.nodeName === 'select' ? (
              isStateInput ? (
                countryDetails?.subdivisionsList?.length || isPreview ? (
                  renderSelectElement(element, label, isStateInput, previewList)
                ) : null
              ) : (
                renderSelectElement(element, label, isStateInput, previewList)
              )
            ) : (isPostalCodeInput && codePattern) || isPreview || !isPostalCodeInput ? (
              <>
                <label style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS_LABEL] }} htmlFor={label.for}>
                  {t(label.nodeValue)}
                </label>
                <input
                  style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT] }}
                  onFocus={onFocus}
                  type={element.type}
                  autoComplete={element.autocomplete}
                  id={element.id}
                  defaultValue={values?.getAll(element.name)}
                  name={element.name}
                  pattern={isPostalCodeInput ? codePattern : null}
                  placeholder={t(element.placeholder)}
                  required={element.required}
                />
              </>
            ) : null}
          </div>
        );
      })}
    </fieldset>
  );
};

FormBuilderFormInterfaceAddress.displayName = 'FormBuilderFormInterfaceAddress';
export default React.memo(FormBuilderFormInterfaceAddress);